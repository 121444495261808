import { styled, css } from "styled-components";

const P = styled.p<{ bold?: boolean; semi?: boolean }>`
  ${({ bold, semi }) => css`
    font-size: 16px;
    line-height: 160%;
    font-family: "Poppins", sans-serif;
    font-weight: ${bold ? 600 : semi ? 500 : 400};
  `}
`;

export default P;
