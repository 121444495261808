import H5 from "../Typo/H5";
import * as S from "./styles";
import Label from "../Typo/Label";

const WHATSAPP_NUMBER = "47 996 005 296";

const OrSendWhatsapp: React.FC<{ whatsappText?: string }> = ({
  whatsappText = "Olá, tudo bem?",
}) => {
  return (
    <S.OrSendWhatsapp>
      <Label>Ou envie um WhatsApp</Label>

      <a
        target="_blank"
        rel="noreferrer"
        href={`https://wa.me/55${WHATSAPP_NUMBER.replace(
          /\s/g,
          ""
        )}?text=${encodeURIComponent(whatsappText)}`}
      >
        <H5 bold>{WHATSAPP_NUMBER}</H5>
      </a>
    </S.OrSendWhatsapp>
  );
};

export default OrSendWhatsapp;
