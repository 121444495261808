const AngledFrame: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="1728"
      height="78"
      viewBox="0 0 1728 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 77.8667L1728 0V77.8667H0Z" fill={color || "#8c54fb"} />
    </svg>
  );
};

export default AngledFrame;
