import P from "../../Typo/P";
import * as S from "./styles";
import H2 from "../../Typo/H2";
import H6 from "../../Typo/H6";
import ButtonCta from "../../ButtonCta";
import Container from "../../Container";
import IconPin from "../../../icons/IconPin";
import IconSale from "../../../icons/IconSale";
import IconPhone from "../../../icons/IconPhone";
import OrSendWhatsapp from "../../OrSendWhatsapp";
import IconFastTime from "../../../icons/IconFastTime";
import IconHumanized from "../../../icons/IconHumanized";

const highligths = [
  {
    id: "1",
    icon: <IconSale />,
    text: "Melhores taxas*",
  },
  {
    id: "2",
    icon: <IconPhone />,
    text: "Contratação facilitada",
  },
  {
    id: "3",
    icon: <IconFastTime />,
    text: "Suporte ágil",
  },
  {
    id: "4",
    icon: <IconHumanized />,
    text: "Atendimento humanizado",
  },
];

const Hero: React.FC = () => {
  return (
    <Container>
      <S.Hero>
        <S.Content>
          <S.TitleDescription>
            <S.Location>
              <S.LocationIcon>
                <IconPin />
              </S.LocationIcon>

              <H6>Jaraguá do Sul/SC e região</H6>
            </S.Location>

            <H2 bold>
              A máquina que <br />
              <span>aumenta seu lucro</span> <br />
              nas vendas com cartão
            </H2>

            <P>
              Com as máquinas be2 você decide o que faz com o seu dinheiro. Aqui
              você escolhe quando e quanto quer antecipar das suas vendas de
              cartão, para aumentar o lucro da sua empresa.
            </P>
          </S.TitleDescription>

          <S.Highlights>
            {highligths.map((item) => (
              <S.Highlight key={item.id}>
                <S.HighlightIcon>{item.icon}</S.HighlightIcon>

                <P semi>{item.text}</P>
              </S.Highlight>
            ))}
          </S.Highlights>

          <S.CallToAction>
            <ButtonCta label="Garanta a sua, agora" />

            <OrSendWhatsapp />
          </S.CallToAction>
        </S.Content>

        <S.HeroImage src="./be2-hero.png" />
      </S.Hero>
    </Container>
  );
};

export default Hero;
