import * as S from "./styles";
import AngledFrame from "../../../icons/AngledFrame";

const Video: React.FC = () => {
  return (
    <S.Video>
      <S.AngledFrame>
        <AngledFrame />
      </S.AngledFrame>

      <S.VideoContainer>
        <S.VideoContent>
          <div>
            <iframe
              allowFullScreen
              title="YouTube video player"
              allow="accelerometer; encrypted-media; gyroscope;"
              src="https://www.youtube.com/embed/FKygTieWDtQ?enablejsapi=1&controls=0&modestbranding=1&rel=0&showinfo=0"
            ></iframe>
          </div>
        </S.VideoContent>
      </S.VideoContainer>

      <S.AngledFrame>
        <AngledFrame />
      </S.AngledFrame>
    </S.Video>
  );
};

export default Video;
