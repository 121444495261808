import P from "../../Typo/P";
import * as S from "./styles";

const flags = [
  {
    id: "1",
    alt: "visa",
    src: "./flag-visa.png",
  },
  {
    id: "2",
    alt: "master",
    src: "./flag-master.png",
  },
  {
    id: "3",
    alt: "elo",
    src: "./flag-elo.png",
  },
  {
    id: "4",
    alt: "hipercard",
    src: "./flag-hipercard.png",
  },
  {
    id: "5",
    alt: "american express",
    src: "./flag-amex.png",
  },
  {
    id: "6",
    alt: "pix",
    src: "./flag-pix.png",
  },
  {
    id: "7",
    alt: "apple pay",
    src: "./flag-apple-pay.png",
  },
  {
    id: "8",
    alt: "google pay",
    src: "./flag-google-pay.png",
  },
  {
    id: "9",
    alt: "samsung pay",
    src: "./flag-samsung-pay.png",
  },
];

const Flags: React.FC = () => {
  return (
    <S.Flags>
      <S.Title>
        <span />

        <P>Aceite as principais bandeiras do mercado</P>

        <span />
      </S.Title>

      <S.Images>
        {flags.map((flag) => (
          <img key={flag.id} src={flag.src} alt={flag.alt} />
        ))}
      </S.Images>
    </S.Flags>
  );
};

export default Flags;
