const IconSmartphone: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="21"
      height="34"
      viewBox="0 0 21 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.37057 0H16.8796C18.7388 0 20.2502 1.51141 20.2502 3.37057V30.6294C20.2502 32.4886 18.7388 34 16.8796 34H3.37057C1.5114 34 0 32.4886 0 30.6294V3.37057C0 1.51141 1.5114 0 3.37057 0ZM16.8796 32.395C17.856 32.395 18.6451 31.6058 18.6451 30.6294V3.37057C18.6451 2.39418 17.856 1.60504 16.8796 1.60504H3.37057C2.39417 1.60504 1.60504 2.39418 1.60504 3.37057V30.6294C1.60504 31.6058 2.39417 32.395 3.37057 32.395H16.8796ZM10.8138 27.7579C11.7284 28.1367 12.1626 29.1852 11.7838 30.0997C11.405 31.0142 10.3566 31.4485 9.44203 31.0697C8.5275 30.6909 8.09322 29.6424 8.47204 28.7279C8.85085 27.8134 9.89931 27.3791 10.8138 27.7579ZM6.9287 3.26318C6.48731 3.26318 6.11279 3.62432 6.11279 4.07908C6.11279 4.52046 6.47394 4.89497 6.9287 4.89497C7.37008 4.89497 7.74457 4.53384 7.74457 4.07908C7.74457 3.62432 7.38346 3.26318 6.9287 3.26318ZM9.73778 3.26318H13.2154C13.6701 3.26318 14.0312 3.62432 14.0312 4.07908C14.0312 4.53384 13.6567 4.89497 13.2154 4.89497H9.73778C9.28302 4.89497 8.92188 4.52046 8.92188 4.07908C8.92188 3.62432 9.2964 3.26318 9.73778 3.26318Z"
        fill={color || "#371f66"}
      />
    </svg>
  );
};

export default IconSmartphone;
