import { styled, css } from "styled-components";

const H5 = styled.h5<{ bold?: boolean; semi?: boolean }>`
  ${({ bold, semi }) => css`
    font-size: 20px;
    line-height: 130%;
    font-family: "Poppins", sans-serif;
    font-weight: ${bold ? 600 : semi ? 500 : 400};
  `}
`;

export default H5;
