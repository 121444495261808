import { useState } from "react";

import * as S from "./styles";
import H3 from "../../Typo/H3";
import H6 from "../../Typo/H6";
import Container from "../../Container";

const faqs = [
  {
    id: "1",
    question: "Tenho que comprar a máquina? ",
    answer:
      "Não, trabalhamos com aluguel de máquina que inclusive pode ter as taxas descontadas ou isentadas dependendo conforme a quantidade de valor transacionado pela sua máquina.",
  },
  {
    id: "2",
    question: "Existe mensalidade da máquina?",
    answer:
      "Sim, trabalhamos com aluguel de máquina que inclusive pode ter as taxas descontadas ou isentadas dependendo conforme a quantidade de valor transacionado pela sua máquina.",
  },
  {
    id: "3",
    question:
      "Consigo escolher a conta domiciliária de qualquer instituição bancária?",
    answer: "Sim, você decide onde o dinheiro das vendas são depositados.",
  },
  {
    id: "4",
    question: "Quais os tipos de máquinas disponíveis?",
    answer:
      "Contamos com máquinas POs as tradicionais (com bobina e impressão de canhoto, funcionado via 3g ou Wifi)  que e as minis (sem impressão de canhoto).",
  },
  {
    id: "5",
    question: "Em caso de problemas com a máquina, como funciona o suporte?",
    answer:
      "Temos máquinas disponíveis como backup que são entregues em poucas horas após a solicitação.",
  },
  {
    id: "6",
    question: "Como funciona a reposição das bobinas da máquina?",
    answer:
      "Enviamos a seu estabelecimento a partir da solicitação pelo nosso portal.",
  },
  {
    id: "7",
    question: "Posso ter mais de uma máquina no meu estabelecimento?",
    answer: "Sim, você pode ter quantas maquinas forem necessárias.",
  },
];

const Faq: React.FC = () => {
  const [openItem, setOpenItem] = useState(0);

  return (
    <Container maxWidth={940} id="faq">
      <S.Faq>
        <H3 semi>
          Respostas para as <span>suas dúvidas :)</span>
        </H3>

        <S.FaqsList>
          {faqs.map((faq, index) => (
            <S.FaqItem
              key={faq.id}
              isOpen={index === openItem}
              onClick={() => setOpenItem(index)}
            >
              <S.Question>
                <H6 semi>{faq.question}</H6>

                <S.Indicator>
                  <span /> <span />
                </S.Indicator>
              </S.Question>

              <S.Answer>{faq.answer}</S.Answer>
            </S.FaqItem>
          ))}
        </S.FaqsList>
      </S.Faq>
    </Container>
  );
};

export default Faq;
