import { styled } from "styled-components";

export const Booz = styled.a`
  gap: 32px;
  width: 100%;
  display: flex;
  cursor: pointer;
  max-width: 920px;
  user-select: none;
  align-self: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px 160px 20px;

  h5 {
    width: 100%;
    max-width: 481px;
    line-height: 150%;
    text-align: center;
  }

  @media (max-width: 740px) {
    h3 {
      font-size: 32px;
    }

    h5 {
      font-size: 16px;
      max-width: 400px;
    }

    svg {
      width: 230px;
      height: 66px;
    }
  }

  @media (max-width: 500px) {
    gap: 20px;
    padding: 0px 20px 100px 20px;
  }
`;

export const Title = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: center;

  span {
    width: 100%;
    height: 1px;
    content: "";
    flex-grow: 1;
    background-color: #3d3d3d;
  }

  h3 {
    color: #1e271a;
    flex-shrink: 0;
  }

  @media (max-width: 500px) {
    justify-content: center;

    span {
      display: none;
    }

    h3 {
      flex-shrink: 1;
      text-align: center;
    }
  }
`;
