import * as S from "./styles";
import Cta from "./components/sections/Cta";
import Faq from "./components/sections/Faq";
import Hero from "./components/sections/Hero";
import Flags from "./components/sections/Flags";
import Video from "./components/sections/Video";
import Rates from "./components/sections/Rates";
import Footer from "./components/sections/Footer";
import Header from "./components/sections/Header";
import Benefits from "./components/sections/Benefits";
import TopLabel from "./components/sections/TopLabel";
import PremiumCustomers from "./components/sections/PremiumCustomers";
import Booz from "./components/sections/Booz";

const Home: React.FC = () => {
  return (
    <S.Home id="home">
      <TopLabel />

      <Header />

      <Hero />

      <Video />

      <Flags />

      <Benefits />

      <Rates />

      <Booz />

      <PremiumCustomers />

      <Faq />

      <Cta />

      <Footer />
    </S.Home>
  );
};

export default Home;
