import * as S from "./styles";
import H3 from "../../Typo/H3";
import H5 from "../../Typo/H5";
import BoozSixteen from "../../../icons/BoozSixteen";

const Booz: React.FC = () => {
  return (
    <S.Booz href="https://www.boozcredito.digital" target="_blank">
      <BoozSixteen />

      <S.Title>
        <span />

        <H3 semi>TRADIÇÃO E CONFIANÇA</H3>

        <span />
      </S.Title>

      <H5>
        A be2 é uma empresa do grupo Booz, com mais de 15 anos de atuação no
        mercado de crédito em Jaraguá do Sul/SC e região.
      </H5>
    </S.Booz>
  );
};

export default Booz;
