import { styled } from "styled-components";

export const Footer = styled.footer`
  gap: 60px;
  display: flex;
  margin-top: 40px;
  padding: 80px 40px;
  align-items: center;
  border-radius: 40px;
  flex-direction: column;
  justify-content: center;
  background-color: #f6f6f6;

  @media (max-width: 520px) {
    padding: 80px 20px;
  }
`;

export const Links = styled.div`
  gap: 20px;
  display: flex;
  width: 100%;
  max-width: 762px;
  justify-content: space-between;

  > div {
    gap: 8px;
    display: flex;
    flex-direction: column;

    a,
    p:not(:first-of-type) {
      cursor: pointer;
    }
  }

  @media (max-width: 700px) {
    > div:first-of-type {
      display: none;
    }
  }

  @media (max-width: 520px) {
    text-align: center;
    align-items: center;
    flex-direction: column;

    > div {
      align-items: center;
    }
  }
`;

export const LogoAndSocialMedia = styled.div`
  width: 100%;
  display: flex;
  max-width: 762px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 420px) {
    gap: 40px;
    align-items: center;
    flex-direction: column-reverse;
  }
`;

export const SocialMedia = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;

  a {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 36px;
    justify-content: center;
    background-color: #3d3d3d;
  }
`;

export const Logo = styled.div`
  width: 94px;
  height: 54px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
