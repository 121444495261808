const IconStar: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="58"
      height="55"
      viewBox="0 0 58 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 0L37.8168 17.8647L57.5317 20.7295L43.2658 34.6353L46.6336 54.2705L29 45L11.3664 54.2705L14.7342 34.6353L0.468304 20.7295L20.1832 17.8647L29 0Z"
        fill={color || "#8c54fb"}
      />
    </svg>
  );
};

export default IconStar;
