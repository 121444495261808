const IconSignal: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.516 0H24.1856C25.328 0 26.2602 0.91911 26.2471 2.06143V23.3585C26.2471 24.5008 25.328 25.42 24.1856 25.42H23.516C22.3737 25.42 21.4546 24.5008 21.4546 23.3585V2.06143C21.4546 0.91911 22.3737 0 23.516 0ZM24.1988 23.8443C24.4614 23.8443 24.6846 23.6343 24.6846 23.3585V2.06143C24.6846 1.79883 24.4745 1.57562 24.1988 1.57562H23.5291C23.2665 1.57562 23.0433 1.7857 23.0433 2.06143V23.3585C23.0433 23.6211 23.2534 23.8443 23.5291 23.8443H24.1988ZM17.0299 6.64404H16.3603C15.2179 6.64404 14.2988 7.56315 14.2988 8.70547V23.3587C14.2988 24.501 15.2179 25.4201 16.3603 25.4201H17.0299C18.1722 25.4201 19.0913 24.501 19.0913 23.3587V8.71861C19.1045 7.57628 18.1722 6.64404 17.0299 6.64404ZM17.5288 23.3587C17.5288 23.6344 17.3056 23.8445 17.043 23.8445H16.3734C16.0976 23.8445 15.8876 23.6213 15.8876 23.3587V8.71861C15.8876 8.44287 16.1108 8.23279 16.3734 8.23279H17.043C17.3187 8.23279 17.5288 8.456 17.5288 8.71861V23.3587ZM9.21719 11.3579H9.88684C11.0292 11.3579 11.9483 12.277 11.9483 13.4193V23.3589C11.9483 24.5012 11.0292 25.4203 9.88684 25.4203H9.21719C8.07487 25.4203 7.15576 24.5012 7.15576 23.3589V13.4193C7.15576 12.277 8.07487 11.3579 9.21719 11.3579ZM9.88684 23.8447C10.1494 23.8447 10.3726 23.6346 10.3726 23.3589V13.4193C10.3726 13.1567 10.1626 12.9335 9.88684 12.9335H9.21719C8.95459 12.9335 8.73138 13.1436 8.73138 13.4193V23.3589C8.73138 23.6215 8.94145 23.8447 9.21719 23.8447H9.88684ZM2.06143 14.9292H2.73108C3.8734 14.9292 4.7925 15.8614 4.7925 16.9906V23.3456C4.7925 24.4879 3.8734 25.4071 2.73108 25.4071H2.06143C0.919104 25.4071 0 24.4879 0 23.3456V16.9906C0 15.8483 0.919104 14.9292 2.06143 14.9292ZM2.73108 23.8446C2.99368 23.8446 3.21689 23.6345 3.21689 23.3587V17.0038C3.21689 16.7412 3.00681 16.5179 2.73108 16.5179H2.06143C1.79882 16.5179 1.57562 16.728 1.57562 17.0038V23.3587C1.57562 23.6214 1.78569 23.8446 2.06143 23.8446H2.73108Z"
        fill={color || "#371f66"}
      />
    </svg>
  );
};

export default IconSignal;
