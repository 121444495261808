import { styled } from "styled-components";

export const Header = styled.header`
  width: 100%;
  display: flex;
  margin-top: 64px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 720px) {
    margin-top: 32px;
  }

  @media (max-width: 350px) {
    justify-content: center;
  }
`;

export const Logo = styled.div`
  width: 140px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 720px) {
    width: 100px;
    height: 60px;
  }
`;

export const Actions = styled.div`
  gap: 32px;
  display: flex;
  align-items: center;

  @media (max-width: 720px) {
    a:first-of-type {
      display: none;
    }
  }

  @media (max-width: 350px) {
    a {
      display: none;
    }
  }
`;
