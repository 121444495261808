import P from "../Typo/P";
import * as S from "./styles";

const ButtonCta: React.FC<{ label: string; href?: string }> = ({
  label,
  href,
}) => {
  return (
    <S.ButtonCta
      target="_blank"
      rel="noopener noreferrer"
      href={
        href ||
        "https://forms.clickup.com/9007176994/f/8cdx792-2253/5YH3YR2BE04ZT1OQ19"
      }
    >
      <P>{label}</P>
    </S.ButtonCta>
  );
};

export default ButtonCta;
