import * as S from "./styles";
import H3 from "../../Typo/H3";
import H5 from "../../Typo/H5";
import ButtonCta from "../../ButtonCta";
import IconStar from "../../../icons/IconStar";
import OrSendWhatsapp from "../../OrSendWhatsapp";

const PremiumCustomers: React.FC = () => {
  return (
    <S.PremiumCustomers>
      <S.Container maxWidth={1040}>
        <S.Content>
          <IconStar />

          <H3 semi>Vende mais de R$25 mil por mês?</H3>

          <H5>
            Entre em contato com a be2 agora mesmo e solicite acesso às taxas
            exclusivas para o seu negócio.
          </H5>

          <S.CallToAction>
            <ButtonCta
              label="Entrar em contato"
              href="https://wa.me/5547996005296?text=Ol%C3%A1,%20vendo%20mais%20de%20R$25mil%20e%20gostaria%20de%20conferir%20as%20taxas%20especiais%20para%20o%20meu%20neg%C3%B3cio"
            />

            <OrSendWhatsapp />
          </S.CallToAction>
        </S.Content>

        <img
          src="./be2-premium-customers.png"
          alt="Empreendedor usa máquina da be2"
        />
      </S.Container>
    </S.PremiumCustomers>
  );
};

export default PremiumCustomers;
