import { styled, css } from "styled-components";

const H3 = styled.h3<{ bold?: boolean; semi?: boolean }>`
  ${({ bold, semi }) => css`
    font-size: 40px;
    line-height: 120%;
    text-transform: uppercase;
    font-family: "Oswald", "Poppins", sans-serif;
    font-weight: ${bold ? 700 : semi ? 600 : 400};
  `}
`;

export default H3;
