import Small from "../../Typo/Small";
import * as S from "./styles";

const TopLabel: React.FC = () => {
  return (
    <S.TopLabel>
      <Small>
        Garanta as melhores taxas, peça a sua máquina de cartão agora mesmo:{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/5547996005296?text=Ol%C3%A1,%20tudo%20bem?"
        >
          47 996 005 296 (WhatsApp)
        </a>
      </Small>
    </S.TopLabel>
  );
};

export default TopLabel;
