import { styled } from "styled-components";
import C from "../../Container";

export const PremiumCustomers = styled.section`
  width: 100%;
  padding: 40px 20px;
  background-color: #f4ffa9;

  img {
    width: 50%;
    max-width: 507px;
  }

  @media (max-width: 740px) {
    img {
      width: 100%;
      max-width: 320px;
    }
  }
`;

export const Container = styled(C)`
  gap: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 740px) {
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  gap: 20px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;

  h3 {
    max-width: 334px;
  }

  h5 {
    max-width: 400px;
    margin-top: 12px;
  }

  @media (max-width: 860px) {
    gap: 12px;

    h3 {
      font-size: 36px;
      max-width: 300px;
    }

    h5 {
      font-size: 16px;
      max-width: 320px;
    }
  }
`;

export const CallToAction = styled.div`
  gap: 20px;
  display: flex;
  margin-top: 32px;
  align-items: center;

  h5 {
    margin-top: 0;
  }

  @media (max-width: 860px) {
    margin-top: 20px;

    a:last-of-type {
      display: none;
    }
  }
`;
