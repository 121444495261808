import { css, styled } from "styled-components";

const H2 = styled.h2<{ bold?: boolean; semi?: boolean }>`
  ${({ bold, semi }) => css`
    font-size: 48px;
    line-height: 120%;
    text-transform: uppercase;
    font-family: "Oswald", "Poppins", sans-serif;
    font-weight: ${bold ? 700 : semi ? 600 : 400};
  `}
`;

export default H2;
