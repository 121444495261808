const IconInstagram: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.272 4.38503C6.72658 4.38503 4.67344 6.4455 4.67344 9C4.67344 11.5545 6.72658 13.615 9.272 13.615C11.8174 13.615 13.8706 11.5545 13.8706 9C13.8706 6.4455 11.8174 4.38503 9.272 4.38503ZM9.272 12.0003C7.62708 12.0003 6.28233 10.6548 6.28233 9C6.28233 7.3452 7.62308 5.99967 9.272 5.99967C10.9209 5.99967 12.2617 7.3452 12.2617 9C12.2617 10.6548 10.9169 12.0003 9.272 12.0003ZM15.1313 4.19625C15.1313 4.79471 14.651 5.27268 14.0587 5.27268C13.4623 5.27268 12.9861 4.7907 12.9861 4.19625C12.9861 3.60181 13.4663 3.11983 14.0587 3.11983C14.651 3.11983 15.1313 3.60181 15.1313 4.19625ZM18.177 5.28874C18.1089 3.84681 17.7807 2.56956 16.7282 1.51724C15.6796 0.464911 14.4069 0.135557 12.9701 0.0632601C11.4892 -0.0210867 7.05076 -0.0210867 5.56994 0.0632601C4.13714 0.131541 2.86443 0.460895 1.81184 1.51322C0.759256 2.56555 0.435075 3.8428 0.363035 5.28473C0.278988 6.77083 0.278988 11.2251 0.363035 12.7113C0.431073 14.1532 0.759256 15.4304 1.81184 16.4828C2.86443 17.5351 4.13314 17.8644 5.56994 17.9367C7.05076 18.0211 11.4892 18.0211 12.9701 17.9367C14.4069 17.8685 15.6796 17.5391 16.7282 16.4828C17.7767 15.4304 18.1049 14.1532 18.177 12.7113C18.261 11.2251 18.261 6.77485 18.177 5.28874ZM16.2639 14.3058C15.9517 15.093 15.3474 15.6995 14.5589 16.0168C13.3783 16.4868 10.5767 16.3783 9.272 16.3783C7.96727 16.3783 5.16171 16.4828 3.98505 16.0168C3.20062 15.7036 2.59628 15.0971 2.2801 14.3058C1.81184 13.1209 1.9199 10.3094 1.9199 9C1.9199 7.69062 1.81584 4.87504 2.2801 3.69419C2.59228 2.90695 3.19662 2.30046 3.98505 1.98315C5.16571 1.51322 7.96727 1.62167 9.272 1.62167C10.5767 1.62167 13.3823 1.51724 14.5589 1.98315C15.3434 2.29644 15.9477 2.90293 16.2639 3.69419C16.7322 4.87906 16.6241 7.69062 16.6241 9C16.6241 10.3094 16.7322 13.125 16.2639 14.3058Z"
        fill={color || "#F6F6F6"}
      />
    </svg>
  );
};

export default IconInstagram;
