import { styled } from "styled-components";

export const Hero = styled.section`
  gap: 20px;
  display: flex;
  padding-top: 64px;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 960px) {
    gap: 40px;
    align-items: center;
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  gap: 48px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;

  @media (max-width: 960px) {
    align-items: center;
  }
`;

export const TitleDescription = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;

  h2 {
    width: 100%;
    color: #371f66;

    span {
      color: #8c54fb;
    }
  }

  p {
    width: 100%;
    max-width: 440px;
  }

  @media (max-width: 1095px) {
    h2 {
      font-size: 40px;
    }
  }

  @media (max-width: 960px) {
    max-width: 600px;
    text-align: center;
    align-items: center;

    h2 {
      br {
        display: none;
      }
    }
  }
`;

export const Location = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;

  @media (max-width: 400px) {
    h6 {
      font-size: 14px;
    }
  }
`;

export const LocationIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 400px) {
    width: 20px;
    height: 20px;
  }
`;

export const Highlights = styled.div`
  width: 100%;
  row-gap: 24px;
  display: grid;
  column-gap: 16px;
  max-width: 464px;
  grid-template-columns: 2fr 3fr;

  @media (max-width: 960px) {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 310px) {
    gap: 16px;
    flex-direction: column;
  }
`;

export const Highlight = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;

  @media (max-width: 960px) {
    gap: 4px;
    max-width: 120px;
    text-align: center;
    flex-direction: column;

    p {
      line-height: 120%;
    }
  }

  @media (max-width: 310px) {
    gap: 12px;
    max-width: 100%;
    flex-direction: row;

    p {
      font-size: 14px;
      text-align: left;
    }
  }
`;

export const HighlightIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 310px) {
    width: 20px;
    height: 20px;
  }
`;

export const CallToAction = styled.div`
  gap: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 470px) {
    flex-direction: column;
  }
`;

export const HeroImage = styled.img`
  width: 50%;
  flex-shrink: 1;
  max-width: 680px;
  margin-top: -20px;

  @media (max-width: 960px) {
    width: 100%;
    max-width: 480px;
  }
`;
