import { styled } from "styled-components";

export const Cta = styled.section`
  z-index: 10;
  display: flex;
  padding: 48px 40px;
  position: relative;
  align-items: center;
  border-radius: 0px 80px;
  justify-content: center;
  background-color: tomato;
  background: linear-gradient(180deg, #8c54fb 0%, #8c54fb 100%);

  img {
    margin-left: -80px;
    margin-bottom: -116px;
  }

  @media (max-width: 850px) {
    img {
      width: 40%;
    }
  }

  @media (max-width: 760px) {
    img {
      position: absolute;
      right: 20px;
      top: 40%;
    }
  }

  @media (max-width: 620px) {
    border-radius: 0px 40px;
  }

  @media (max-width: 560px) {
    justify-content: flex-start;
  }

  @media (max-width: 520px) {
    flex-direction: column-reverse;

    img {
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      max-width: 240px;
      margin-top: -20px;
      position: relative;
    }
  }

  @media (max-width: 360px) {
    background-color: tomato !important;
    padding: 48px 20px;
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
  }
`;

export const Content = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h3 {
    width: 100%;
    color: #fff;
    max-width: 392px;
  }

  @media (max-width: 760px) {
    h3 {
      font-size: 32px;
      max-width: 320px;
    }

    p {
      font-size: 14px;
    }
  }

  @media (max-width: 300px) {
    a {
      text-align: center;
    }
  }
`;

export const Benefits = styled.ul`
  gap: 20px;
  display: flex;
  list-style: none;
  align-items: center;

  @media (max-width: 300px) {
    flex-direction: column;
    gap: 4px;
  }
`;

export const Benefit = styled.li`
  gap: 12px;
  display: flex;
  align-items: center;

  @media (max-width: 940px) {
    gap: 4px;

    p {
      font-size: 14px;
      white-space: nowrap;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    &:last-of-type {
      svg {
        width: 20px;
      }
    }
  }

  @media (max-width: 560px) {
    &:first-of-type {
      display: none;
    }
  }
`;
