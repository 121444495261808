import { styled } from "styled-components";

export const TopLabel = styled.section`
  width: 100%;
  padding: 6px 20px;
  text-align: center;
  background-color: #f4ffa9;

  small {
    line-height: 100%;
  }

  a {
    font-weight: 600;
  }

  @media (max-width: 420px) {
    display: none;
  }
`;
