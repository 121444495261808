import { styled } from "styled-components";

import H6 from "../../Typo/H6";

export const Rates = styled.section`
  gap: 64px;
  display: flex;
  padding-top: 120px;
  padding-bottom: 100px;
  flex-direction: column;
  border-top: 1px solid #c9c9c9;

  @media (max-width: 740px) {
    h3 {
      font-size: 32px;
    }

    h5 {
      font-size: 16px;
      max-width: 440px;
    }

    h6 {
      font-size: 14px;
    }

    img {
      width: 44px;
    }
  }
`;

export const TitleDescription = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  max-width: 536px;
  flex-direction: column;

  h3 {
    color: #5f65f2;
  }
`;

export const MainRates = styled.div`
  gap: 8px;
  display: flex;
  overflow-x: auto;
  flex-direction: column;

  @media (max-width: 520px) {
    margin-right: -20px;
    margin-left: -20px;
  }
`;

export const Others = styled(H6)``;

export const MainRatesRow = styled.div`
  height: 88px;
  display: grid;
  min-width: 400px;
  border-radius: 80px;
  align-items: center;
  padding: 0 80px 0 40px;
  background-color: #f4f4f4;
  grid-template-columns: 2fr 1fr 1fr 1fr;

  h6:not(:first-of-type),
  ${Others}, img {
    margin: 0 auto;
  }

  &:first-of-type {
    height: 48px;
    background-color: transparent;
  }

  @media (max-width: 600px) {
    padding: 0 32px;
    height: 64px;
  }
`;

export const AntecipationRates = styled.div`
  gap: 8px;
  display: flex;
  overflow-x: auto;
  flex-direction: column;

  @media (max-width: 600px) {
    margin-right: -20px;
    margin-left: -20px;
  }
`;

export const AntecipationRatesHeading = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;

  span {
    width: 100%;
    height: 1px;
    background-color: #3d3d3d;
  }

  h5 {
    white-space: nowrap;
  }

  div {
    gap: 12px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 600px) {
    padding-left: 20px;

    span {
      display: none;
    }
  }
`;

export const AntecipationRatesTable = styled.div`
  gap: 8px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

export const Label = styled(H6)`
  margin: 0 0 !important;
`;

export const AnteciationRatesRow = styled.div`
  height: 88px;
  display: grid;
  padding: 0 40px;
  min-width: 520px;
  border-radius: 80px;
  align-items: center;
  background-color: #f4f4f4;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  h6 {
    margin: 0 auto;
  }

  &:first-of-type {
    height: 48px;
    background-color: transparent;
  }

  @media (max-width: 600px) {
    padding: 0 32px;
    height: 64px;
  }
`;
