const IconPhone: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4157 25C19.39 25 19.3642 25 19.3384 25C12.4929 24.8323 6.769 19.0537 6.52406 18.8086L6.18887 18.4732C5.89236 18.1765 0.168453 12.4753 0.000861437 5.65182C-0.0249219 4.49093 0.529421 3.39453 1.48341 2.72379L4.78368 0.363316C5.24778 0.0279475 5.82791 -0.075243 6.38225 0.0537449C6.93659 0.195632 7.40069 0.556798 7.64563 1.05985L10.5978 6.903C10.9201 7.54794 10.7525 8.34767 10.1853 8.79912L8.67697 9.99871C8.48359 10.1535 8.43203 10.4373 8.58673 10.6437C8.59962 10.6694 10.933 13.907 14.3751 16.4352C14.5685 16.5771 14.8521 16.5255 15.0068 16.332L16.2057 14.8229C16.6569 14.2553 17.4433 14.0747 18.1008 14.4101L23.9407 17.3639C24.4435 17.6219 24.8174 18.0863 24.9463 18.628C25.0752 19.1697 24.9721 19.7631 24.6369 20.2274L22.2777 23.5295C21.5944 24.4454 20.5373 25 19.4157 25ZM5.90526 1.80798C5.87947 1.80798 5.85369 1.82088 5.82791 1.83378L2.52763 4.19426C2.06353 4.52962 1.79281 5.05848 1.8057 5.61312C1.94751 11.74 7.40069 17.1317 7.45226 17.1833C7.45226 17.1833 7.45226 17.1833 7.46515 17.1962L7.81323 17.5445C7.87768 17.609 13.2793 23.0523 19.39 23.1942C19.9443 23.2071 20.4729 22.9362 20.808 22.4718L23.1672 19.1697C23.193 19.1311 23.193 19.0924 23.193 19.0537C23.1801 19.015 23.1543 18.9892 23.1286 18.9634L17.482 16.1127L16.4249 17.4542C15.6643 18.4216 14.2849 18.6151 13.3051 17.8928C9.61806 15.184 7.20732 11.8303 7.10418 11.6885C6.40803 10.6952 6.6143 9.32797 7.55539 8.57984L8.89613 7.52214L6.04706 1.87247C6.03417 1.83378 5.9955 1.80798 5.95682 1.80798C5.91815 1.80798 5.91815 1.80798 5.90526 1.80798Z"
        fill={color || "#371f66"}
      />
    </svg>
  );
};

export default IconPhone;
