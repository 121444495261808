import { styled, css } from "styled-components";

const Small = styled.small<{ bold?: boolean; semi?: boolean }>`
  ${({ bold, semi }) => css`
    font-size: 14px;
    line-height: 140%;
    font-family: "Poppins", sans-serif;
    font-weight: ${bold ? 600 : semi ? 500 : 400};
  `}
`;

export default Small;
