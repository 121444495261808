import { styled } from "styled-components";

const Container = styled.div<{ maxWidth?: number }>`
  width: 100%;
  display: flex;
  margin: 0 auto;
  padding: 0 20px;
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth || 1320}px;
`;

export default Container;
