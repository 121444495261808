import { styled } from "styled-components";
import H5 from "../../Typo/H5";

export const Subtitle = styled(H5)``;

export const Benefits = styled.section`
  gap: 12px;
  display: flex;
  padding-top: 40px;
  text-align: center;
  align-items: center;
  padding-bottom: 120px;
  flex-direction: column;

  h3 {
    color: #8c54fb;
  }

  ${Subtitle} {
    width: 100%;
    max-width: 470px;
  }

  @media (max-width: 640px) {
    h3 {
      font-size: 36px;
    }

    ${Subtitle} {
      font-size: 16px;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: 1000px;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const ImageMobile = styled.img`
  width: 100%;
  display: none;

  @media (max-width: 500px) {
    display: block;
  }
`;

export const BenefitsContainer = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 920px;
  margin-top: 48px;
  margin-bottom: 48px;
  justify-content: space-between;

  @media (max-width: 984px) {
    gap: 20px;
    justify-content: center;
  }
`;

export const BenefitIcon = styled.div``;

export const Benefit = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  max-width: 400px;
  text-align: left;
  flex-direction: column;

  ${BenefitIcon} {
    width: 64px;
    height: 55px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &:nth-of-type(1) {
    ${BenefitIcon} {
      width: 56px;
    }
  }

  &:nth-of-type(2) {
    ${BenefitIcon} {
      height: 60px;
    }
  }

  @media (max-width: 984px) {
    width: 45%;
  }

  @media (max-width: 620px) {
    width: 100%;
  }

  @media (max-width: 400px) {
    ${BenefitIcon} {
      width: 48px;
      height: 40px;
    }

    &:nth-of-type(1) {
      ${BenefitIcon} {
        width: 42px;
      }
    }

    &:nth-of-type(2) {
      ${BenefitIcon} {
        height: 45px;
      }
    }
  }
`;
