import * as S from "./styles";
import Logo from "../../../icons/Logo";
import Container from "../../Container";
import ButtonCta from "../../ButtonCta";

const Header: React.FC = () => {
  return (
    <Container>
      <S.Header>
        <S.Logo>
          <Logo />
        </S.Logo>

        <S.Actions>
          <ButtonCta label="Peça a sua máquina" />

          <a href="https://www.boozcredito.digital/portal-booz/////#entrar">
            Acessar portal
          </a>
        </S.Actions>
      </S.Header>
    </Container>
  );
};

export default Header;
