import P from "../../Typo/P";
import * as S from "./styles";
import Logo from "../../../icons/Logo";
import Container from "../../Container";
import IconYoutube from "../../../icons/IconYoutube";
import IconWhatsapp from "../../../icons/IconWhatsapp";
import IconInstagram from "../../../icons/IconInstagram";

const Footer: React.FC = () => {
  const scrollTo = (id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Container maxWidth={1280}>
        <S.Footer>
          <S.Links>
            <div>
              <P semi>Navegação</P>

              <P onClick={() => scrollTo("home")}>Home</P>

              <P onClick={() => scrollTo("benefits")}>Diferenciais</P>

              <P onClick={() => scrollTo("rates")}>Taxas</P>

              <P onClick={() => scrollTo("faq")}>Perguntas frequentes</P>
            </div>

            <div>
              <P semi>Suporte</P>

              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:contatobe2@boozservicos.com.br"
              >
                contatobe2@boozservicos.com.br
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/5547996005296"
              >
                47 996 005 296
              </a>
            </div>

            <div>
              <P semi>Recursos</P>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.boozcredito.digital/portal-booz/////#entrar"
              >
                <P>Acesso ao portal</P>
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://forms.clickup.com/9007176994/f/8cdx792-2253/5YH3YR2BE04ZT1OQ19"
              >
                <P>Peça sua máquina</P>
              </a>
            </div>
          </S.Links>

          <S.LogoAndSocialMedia>
            <S.Logo>
              <Logo />
            </S.Logo>

            <S.SocialMedia>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/be2pay"
              >
                <IconInstagram />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://youtube.com/@be2pay"
              >
                <IconYoutube />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/5547996005296"
              >
                <IconWhatsapp />
              </a>
            </S.SocialMedia>
          </S.LogoAndSocialMedia>
        </S.Footer>
      </Container>

      <P style={{ textAlign: "center", padding: "40px 20px" }}>
        © {new Date().getFullYear()} - Todos os direitos reservados à be2
      </P>
    </>
  );
};

export default Footer;
