import { styled } from "styled-components";

export const Flags = styled.section`
  gap: 12px;
  width: 100%;
  display: flex;
  padding: 0 20px;
  margin-top: 48px;
  max-width: 860px;
  align-self: center;
  align-items: center;
  margin-bottom: 48px;
  flex-direction: column;
`;

export const Title = styled.div`
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: center;

  span {
    width: 100%;
    height: 1px;
    content: "";
    flex-grow: 1;
    background-color: #3d3d3d;
  }

  p {
    flex-shrink: 0;
  }

  @media (max-width: 440px) {
    span {
      display: none;
    }

    p {
      flex-shrink: 1;
      text-align: center;
    }
  }
`;

export const Images = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  img {
    /* background-color: tomato; */
  }
`;
