import P from "../../Typo/P";
import H3 from "../../Typo/H3";
import * as S from "./styles";
import ButtonCta from "../../ButtonCta";
import Container from "../../Container";
import IconSignal from "../../../icons/IconSignal";
import IconPaperRoll from "../../../icons/IconPaperRoll";
import IconSmartphone from "../../../icons/IconSmartphone";

const benefits = [
  {
    id: "1",
    icon: <IconSmartphone />,
    label: "Plataforma de gestão",
  },
  {
    id: "2",
    icon: <IconPaperRoll />,
    label: "Bobina grátis",
  },
  {
    id: "3",
    icon: <IconSignal />,
    label: "3G, 4G e Wi-fi",
  },
];

const Cta: React.FC = () => {
  return (
    <Container>
      <S.Cta>
        <S.Content>
          <S.Benefits>
            {benefits.map((benefit) => (
              <S.Benefit key={benefit.id}>
                <div>{benefit.icon}</div>

                <P semi>{benefit.label}</P>
              </S.Benefit>
            ))}
          </S.Benefits>

          <H3 semi>Está esperando o que para lucrar mais?</H3>

          <ButtonCta label="Garanta já a sua máquina" />
        </S.Content>

        <img src="./be2-maquina.png" alt="Máquina be2, a roxinha" />
      </S.Cta>
    </Container>
  );
};

export default Cta;
