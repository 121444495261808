import P from "../../Typo/P";
import H3 from "../../Typo/H3";
import H5 from "../../Typo/H5";
import * as S from "./styles";
import IconProfit from "../../../icons/IconProfit";
import IconTransparency from "../../../icons/IconTransparency";
import IconConnectivity from "../../../icons/IconConnectivity";
import ButtonCta from "../../ButtonCta";
import IconHumanized from "../../../icons/IconHumanized";
import Container from "../../Container";

const benefits = [
  {
    id: "1",
    icon: <IconProfit />,
    title: "Lucratividade para suas vendas",
    description:
      "As suas vendas não são antecipadas automaticamente, você define o que é melhor para sua empresa, não dividimos o seu lucro e você decide qual parcela quer antecipar ou não.",
  },
  {
    id: "2",
    icon: <IconTransparency />,
    title: "Transparência para seu fluxo de caixa",
    description:
      "Acompanhe no portal be2 todas as vendas da sua empresa, defina individualmente cada parcela que você quer ou não antecipar, entendendo o custo de juros de cada parcela.",
  },
  {
    id: "3",
    icon: <IconHumanized color="#8c54fb" />,
    title: "Suporte humanizado para você",
    description:
      "Atendimento acessível com suporte técnico humanizado, troca de máquinas com velocidade em caso de problemas, atualizações regulares de software e novos recursos.",
  },
  {
    id: "4",
    icon: <IconConnectivity />,
    title: "Conectividade e facilidade de uso",
    description:
      "Sua máquina com conectividade estável e rápida, seja por meio de redes móveis ou conexão Wi-Fi, com interfaces intuitivas e fáceis de usar. Isso agiliza o atendimento ao cliente e minimiza erros de operação.",
  },
];

const Benefits: React.FC = () => {
  return (
    <Container id="benefits">
      <S.Benefits>
        <H3 semi>Chega de dividir seus lucros!</H3>

        <S.Subtitle>
          Com a be2 você decide, de uma forma fácil, quais parcelas quer
          antecipar ou não
        </S.Subtitle>

        <S.Image src="./be2-benefits.png" alt="Benefícios da be2 pay" />
        <S.ImageMobile
          src="./be2-beneficios-mobile.png"
          alt="Benefícios da be2 pay"
        />

        <S.BenefitsContainer>
          {benefits.map((benefit) => (
            <S.Benefit key={benefit.id}>
              <S.BenefitIcon>{benefit.icon}</S.BenefitIcon>

              <H5 semi>{benefit.title}</H5>

              <P>{benefit.description}</P>
            </S.Benefit>
          ))}
        </S.BenefitsContainer>

        <ButtonCta label="Peça a sua máquina" />
      </S.Benefits>
    </Container>
  );
};

export default Benefits;
