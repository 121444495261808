import { css, styled } from "styled-components";

import H6 from "../../Typo/H6";

export const Faq = styled.section`
  gap: 40px;
  display: flex;
  padding: 120px 20px;
  flex-direction: column;

  h3 {
    width: 100%;
    color: #371f66;
    max-width: 333px;

    span {
      color: #8c54fb;
    }
  }

  @media (max-width: 640px) {
    padding: 80px 0;

    h3 {
      font-size: 36px;
    }

    h6 {
      font-size: 16px;
    }
  }

  @media (max-width: 400px) {
    h3 {
      font-size: 24px;
    }
  }
`;

export const FaqsList = styled.ul`
  gap: 12px;
  display: flex;
  list-style: none;
  flex-direction: column;
`;

export const Answer = styled(H6)`
  padding-right: 56px;

  @media (max-width: 520px) {
    padding-right: 20px;
  }
`;

export const Indicator = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  justify-content: center;

  span {
    width: 18px;
    height: 3px;
    position: absolute;
    background-color: #8c54fb;
  }

  span:last-of-type {
    transition: transform 0.2s ease-in-out;
  }

  @media (max-width: 520px) {
    span {
      width: 12px;
      height: 2px;
    }
  }
`;

export const FaqItem = styled.li<{ isOpen: boolean }>`
  ${({ isOpen }) => css`
    gap: 32px;
    display: flex;
    cursor: pointer;
    user-select: none;
    flex-direction: column;
    background-color: #f6f6f6;
    border-radius: ${isOpen ? "24px" : "40px"};
    padding: ${isOpen ? "48px 40px" : "24px 40px"};

    ${Answer} {
      display: ${isOpen ? "block" : "none"};
    }

    ${Indicator} {
      span:last-of-type {
        transform: rotateZ(${isOpen ? "0deg" : "-90deg"});
      }
    }

    @media (max-width: 520px) {
      gap: 12px;
      padding: ${isOpen ? "24px 20px" : "16px 20px"};

      h6 {
        font-size: 14px;
      }
    }
  `}
`;

export const Question = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
