const IconFastTime: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="34"
      height="22"
      viewBox="0 0 34 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.13696 0H22.9398C28.9858 0 33.8948 4.92236 33.8948 10.9683C33.8948 17.0143 28.9724 21.9366 22.9264 21.9366C19.141 21.9366 15.6499 19.9971 13.6435 16.8136H11.9448C11.3161 16.8136 10.8078 16.3053 10.8078 15.6767C10.8078 15.048 11.3161 14.5397 11.9448 14.5397H14.2989C14.7136 14.5397 15.1015 14.7671 15.3021 15.1282C16.827 17.9238 19.7563 19.6627 22.9398 19.6627C27.7284 19.6627 31.6342 15.7569 31.6342 10.9683C31.6342 6.17971 27.7284 2.27392 22.9398 2.27392H1.13696C0.508288 2.27392 0 1.76563 0 1.13696C0 0.508288 0.508288 0 1.13696 0ZM21.8003 4.96252C21.8003 4.33385 22.3086 3.82556 22.9373 3.82556C23.5659 3.82556 24.0742 4.33385 24.0742 4.96252V10.7276L26.629 13.2824C27.0704 13.7238 27.0704 14.4461 26.629 14.8875C26.415 15.1149 26.1207 15.2219 25.8265 15.2219C25.5322 15.2219 25.2513 15.1149 25.0239 14.8875L22.1347 11.9983C21.9207 11.7843 21.8003 11.5034 21.8003 11.1957V4.96252ZM13.6023 10.9683C13.6023 10.3396 13.094 9.8313 12.4654 9.8313H8.43921C7.81053 9.8313 7.30225 10.3396 7.30225 10.9683C7.30225 11.5969 7.81053 12.1052 8.43921 12.1052H12.4654C13.094 12.1052 13.6023 11.5969 13.6023 10.9683ZM13.7638 5.00269C14.3924 5.00269 14.9007 5.51097 14.9007 6.13964C14.9007 6.76832 14.3791 7.2766 13.7638 7.2766H4.57446C3.94579 7.2766 3.4375 6.76832 3.4375 6.13964C3.4375 5.51097 3.94579 5.00269 4.57446 5.00269H13.7638Z"
        fill={color || "#371f66"}
      />
    </svg>
  );
};

export default IconFastTime;
