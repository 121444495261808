import { styled } from "styled-components";

export const Video = styled.div`
  padding-top: 80px;
`;

export const AngledFrame = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: -1px;

  &:last-of-type {
    margin-top: -1px;
    transform: rotateZ(180deg);
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  padding: 64px 20px;
  justify-content: center;
  background-color: #8c54fb;

  @media (max-width: 500px) {
    padding: 32px 20px;
  }
`;

export const VideoContent = styled.div`
  width: 100%;
  max-width: 800px;
  position: relative;

  div {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    padding-bottom: 56.25%;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
`;
