const IconWhatsapp: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0045 2.90625C15.1339 1.03125 12.6429 0 9.99554 0C4.53125 0 0.0848214 4.44643 0.0848214 9.91071C0.0848214 11.6562 0.540179 13.3616 1.40625 14.8661L0 20L5.25446 18.6205C6.70089 19.4107 8.33036 19.8259 9.99107 19.8259H9.99554C15.4554 19.8259 20 15.3795 20 9.91518C20 7.26786 18.875 4.78125 17.0045 2.90625ZM9.99554 18.1563C8.51339 18.1563 7.0625 17.7589 5.79911 17.0089L5.5 16.8304L2.38393 17.6473L3.21429 14.6071L3.01786 14.2946C2.19196 12.9821 1.75893 11.4688 1.75893 9.91071C1.75893 5.37054 5.45536 1.67411 10 1.67411C12.2009 1.67411 14.2679 2.53125 15.8214 4.08929C17.375 5.64732 18.3304 7.71429 18.3259 9.91518C18.3259 14.4598 14.5357 18.1563 9.99554 18.1563ZM14.5134 11.9866C14.2679 11.8616 13.0491 11.2634 12.8214 11.183C12.5938 11.0982 12.4286 11.058 12.2634 11.308C12.0982 11.558 11.625 12.1116 11.4777 12.2813C11.3348 12.4464 11.1875 12.4688 10.942 12.3438C9.48661 11.6161 8.53125 11.0446 7.57143 9.39732C7.31696 8.95982 7.82589 8.99107 8.29911 8.04464C8.37946 7.87946 8.33929 7.73661 8.27679 7.61161C8.21429 7.48661 7.71875 6.26786 7.51339 5.77232C7.3125 5.29018 7.10714 5.35714 6.95536 5.34821C6.8125 5.33929 6.64732 5.33929 6.48214 5.33929C6.31696 5.33929 6.04911 5.40179 5.82143 5.64732C5.59375 5.89732 4.95536 6.49554 4.95536 7.71429C4.95536 8.93304 5.84375 10.1116 5.96429 10.2768C6.08929 10.442 7.70982 12.942 10.1964 14.0179C11.7679 14.6964 12.3839 14.7545 13.1696 14.6384C13.6473 14.567 14.6339 14.0402 14.8393 13.4598C15.0446 12.8795 15.0446 12.3839 14.9821 12.2813C14.9241 12.1696 14.7589 12.1071 14.5134 11.9866Z"
        fill={color || "#F6F6F6"}
      />
    </svg>
  );
};

export default IconWhatsapp;
