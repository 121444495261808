import axios from "axios";
import { useEffect, useState } from "react";

import * as S from "./styles";
import H3 from "../../Typo/H3";
import H5 from "../../Typo/H5";
import H6 from "../../Typo/H6";
import Container from "../../Container";
import { IAntRate, IRate } from "./types";

const Rates: React.FC = () => {
  const [rates, setRates] = useState<IRate[]>([]);
  const [antecipationRates, setAntecipationRates] = useState<IAntRate[]>([]);

  useEffect(() => {
    const run = async () => {
      const data = (
        await axios.get(
          "https://production-be2-rates.s3.amazonaws.com/rates.json"
        )
      ).data;

      if (data.rates) setRates(data.rates);
      if (data.antecipationRates) setAntecipationRates(data.antecipationRates);
    };

    run();
  }, []);

  return (
    <Container maxWidth={1000} id="rates">
      <S.Rates>
        <S.TitleDescription>
          <H3 semi>Taxas sem pegadinhas</H3>

          <H5>
            Seja para vender à vista ou parcelado, a be2 oferece as melhores
            taxas da região*, sem pegadinhas
          </H5>
        </S.TitleDescription>

        {rates.length > 0 ? (
          <S.MainRates>
            <S.MainRatesRow>
              <span />

              <img alt="Visa" src="./flag-visa.png" />

              <img alt="Mastercard" src="./flag-master.png" />

              <S.Others semi>Outros</S.Others>
            </S.MainRatesRow>

            {rates.map((rate) => (
              <S.MainRatesRow key={rate.id}>
                <H6 semi>{rate.label}</H6>

                <H6>{rate.visa}</H6>

                <H6>{rate.master}</H6>

                <H6>{rate.others}</H6>
              </S.MainRatesRow>
            ))}
          </S.MainRates>
        ) : null}

        {antecipationRates.length > 0 ? (
          <S.AntecipationRates>
            <S.AntecipationRatesHeading>
              <span />

              <div>
                <H5>Taxas de antecipação</H5>

                <img alt="Visa" src="./flag-visa.png" />

                <img alt="Mastercard" src="./flag-master.png" />
              </div>

              <span />
            </S.AntecipationRatesHeading>

            <S.AntecipationRatesTable>
              <S.AnteciationRatesRow>
                <span />
                <H6 semi>À vista</H6>
                <H6 semi>2x</H6>
                <H6 semi>4x</H6>
                <H6 semi>6x</H6>
                <H6 semi>8x</H6>
                <H6 semi>10x</H6>
                <H6 semi>12x</H6>
              </S.AnteciationRatesRow>

              {antecipationRates.map((rate) => (
                <S.AnteciationRatesRow key={rate.id}>
                  <S.Label semi>{rate.label}</S.Label>
                  <H6>{rate.one}</H6>
                  <H6>{rate.two}</H6>
                  <H6>{rate.four}</H6>
                  <H6>{rate.six}</H6>
                  <H6>{rate.eight}</H6>
                  <H6>{rate.ten}</H6>
                  <H6>{rate.twelve}</H6>
                </S.AnteciationRatesRow>
              ))}
            </S.AntecipationRatesTable>
          </S.AntecipationRates>
        ) : null}
      </S.Rates>
    </Container>
  );
};

export default Rates;
