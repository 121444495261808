const IconYoutube: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1718 0.65304C17.9859 0.87481 18.627 1.52825 18.8446 2.35807C19.24 3.86201 19.24 7 19.24 7C19.24 7 19.24 10.1379 18.8446 11.6419C18.627 12.4717 17.9859 13.1252 17.1718 13.3471C15.6965 13.75 9.78 13.75 9.78 13.75C9.78 13.75 3.86352 13.75 2.38808 13.3471C1.57399 13.1252 0.932858 12.4717 0.715278 11.6419C0.32 10.1379 0.32 7 0.32 7C0.32 7 0.32 3.86201 0.715278 2.35807C0.932858 1.52825 1.57399 0.87481 2.38808 0.65304C3.86352 0.25 9.78 0.25 9.78 0.25C9.78 0.25 15.6965 0.25 17.1718 0.65304ZM8.00625 4.3587V10.2283L12.7362 7.29359L8.00625 4.3587Z"
        fill={color || "#F6F6F6"}
      />
    </svg>
  );
};

export default IconYoutube;
